import React, { Suspense, useState } from "react";
import { fullScreenContainer } from "../styles/general";
import { AppBar, Button, IconButton, Tab, Tabs, Typography, Box, Fade, LinearProgress } from "@mui/material";
import { StorageManager } from "@unity/components";
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { useHistory } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
const NoDateTask = React.lazy(() => import("../modules/NoDateTask"));

const LoadingComponent = ({component}) => (
    <Suspense fallback={<Fade in={true}><LinearProgress/></Fade>}>
        {component}
    </Suspense>
)


export default function TasksNoDateTask({context}) {
  let history = useHistory();

  // -------------------------------------------------------------------------------
  // This is just an example of how we are implementing session cache at the moment.
  // If you use make sure the module name is part of the key to avoid conflicts!
  const sm = new StorageManager();

  // -------------------------------------------------------------------------------

  const getTab = () => {
    return <LoadingComponent component={<NoDateTask context={context}/>} /> 
  }

  return (
    <>
        <Box
            style={{
                backgroundColor: "rgb(33, 0, 183)",
                height: "90px",
                padding: "2px",
                width:"100%",         
                top: 0,
            }}>
            <IconButton
                onClick={() => {window.location.pathname = `/tasks/index`}}
                onTouchStart={() => {window.location.pathname = `/jobs/index`}}
                style={{
                    paddingHorizontal: "16px",
                    paddingVertical: "8px",
                    borderRadius:"4px",
                    backgroundColor: "rgb(255, 255, 255)",
                    fontSize: "0.875rem",
                    height: "40px",
                    color:'#000000',
                    width: "64px",
                    top: "25px",
                    left: "10px"
                }}
            >
                <KeyboardBackspace />
            </IconButton>
            <Typography 
                variant="h6"
                style={{
                    color:'#ffffff',
                    marginLeft: "100px",
                    paddingTop: "0px",
                    marginTop: "-10px"
                }}>
                Task {<IoMdArrowDropright/>} Tasks With No Date
            </Typography>

           
        </Box>

        <div>
            { getTab() }
        </div>
    </>
  )
}
