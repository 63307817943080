import React, { Suspense, useState } from "react";
import { AppBar, Box, Button, Fade, LinearProgress, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { StorageManager } from "@unity/components";
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { useHistory } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const WorkPackages = React.lazy(() => import("../modules/WorkPackages"));
const MyTaskDashboard = React.lazy(() => import("../modules/MyTaskDashboard"));
const LiveTasks = React.lazy(() => import("../modules/LiveTasks"));
const AllTasks = React.lazy(() => import("../modules/AllTasks"));

const LoadingComponent = ({component}) => (
    <Suspense fallback={<Fade in={true}><LinearProgress/></Fade>}>
        {component}
    </Suspense>
)


export default function Tasks({context}) {
    const [tab, setTab] = useState(2);
    const [subpage, setSubpage] = useState("AllTasks");
    let history = useHistory();

  // -------------------------------------------------------------------------------
  // This is just an example of how we are implementing session cache at the moment.
  // If you use make sure the module name is part of the key to avoid conflicts!
  const sm = new StorageManager();

  if(sm.checkGrant()) {
    let t = parseInt(sm.getItem("tasks-index-tab", 0));
    if(!isNaN(t) && t !== tab) {
        setTab(t);
    }
  }

  const handleTabChange = (value) => {
    setTab(value);
    switch(value){
        case 0: setSubpage("Dashboard"); break;
        case 1: setSubpage("Work Packages"); break;
        case 2: setSubpage("LiveTasks"); break;
        case 3: setSubpage("AllTasks"); break;
        default: setSubpage("Dashboard");
    }
    sm.setItem("tasks-index-tab", value);
  }
  // -------------------------------------------------------------------------------

  const getTab = () => {
    if(!isMobile)
    {
        switch(tab) {
            case 0: return <LoadingComponent component={<MyTaskDashboard context={context}/>} />; break;
            case 1: return <LoadingComponent component={<WorkPackages context={context}/>} />; break;
            case 2: return <LoadingComponent component={<LiveTasks context={context}/>} />; break;
            case 3: return <LoadingComponent component={<AllTasks context={context}/>} />; break;
            default: return (
                <Typography 
                    variant="h5" 
                    gutterBottom
                >
                    Tab Not Found. Please Refresh.
                </Typography>
            )
        }
    }
    else
    {
        return <LoadingComponent component={<LiveTasks context={context}/>} />;
    }
  }

  return (
    <>
    {!isMobile ?
    <Box>
        <Box
            style={{
                backgroundColor: "rgb(33, 0, 183)",
                height: "90px",
                padding: "2px",
                width:"100%",         
                top: 0,
            }}>
            <IconButton
                onClick={() => {window.location.pathname = `/jobs/index`}}
                onTouchStart={() => {window.location.pathname = `/jobs/index`}}
                style={{
                    paddingHorizontal: "16px",
                    paddingVertical: "8px",
                    borderRadius:"4px",
                    backgroundColor: "rgb(255, 255, 255)",
                    fontSize: "0.875rem",
                    height: "40px",
                    color:'#000000',
                    width: "64px",
                    top: "25px",
                    left: "10px"
                }}
            >
                <KeyboardBackspace />
            </IconButton>
            <Typography 
                variant="h6"
                style={{
                    color:'#ffffff',
                    marginLeft: "100px",
                    paddingTop: "0px",
                    marginTop: "-10px"
                }}>
                Tasks
            </Typography>

           
        </Box>
        <Box>
            <AppBar position="static">
                <Tabs
                    value={tab}
                    onChange={(e, val) => {handleTabChange(val)}}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    sx={[{
                        backgroundColor: '#fff',
                        color: '#212529'
                    }]}
                >
                    <Tab 
                        label="Dashboard"
                    />
                    <Tab 
                        label="Work Packages"
                    />
                    <Tab 
                        label="Live Tasks"
                    />
                    <Tab 
                        label="All Task Templates"
                    />
                </Tabs>
            </AppBar>
        </Box>
    </Box>
    :
    <Box>

        <Box
            style={{
                backgroundColor: "rgb(33, 0, 183)",
                height: "90px",
                padding: "2px",
                width:"100%",         
                top: 0,
            }}>
            <IconButton
                onClick={() => {window.location.pathname = `/tasks/index`}}
                onTouchStart={() => {window.location.pathname = `/tasks/index`}}
                style={{
                    paddingHorizontal: "16px",
                    paddingVertical: "8px",
                    borderRadius:"4px",
                    backgroundColor: "rgb(255, 255, 255)",
                    fontSize: "0.875rem",
                    height: "40px",
                    color:'#000000',
                    width: "64px",
                    top: "25px",
                    left: "30px"
                }}
            >
                <KeyboardBackspace />
            </IconButton>
            <Typography 
                variant="h6"
                style={{
                    color:'#ffffff',
                    marginLeft: "100px",
                    paddingTop: "0px",
                    marginTop: "-10px"
                }}>
                Tasks
            </Typography>

           
        </Box>
    </Box>
    }
        <div>
            { getTab() }
        </div>
    </>
  )
}
